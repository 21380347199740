import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { fetchPlantNames } from "../service/plant-service";
import { usePlantSelection } from "../hooks/usePlantSelection";
import { usePlantNaming } from "../hooks/usePlantNaming";

function PlantDetailsStep({ plantData, setPlantData }) {
  const [plantLocations, setPlantPlantLocations] = useState([]);
  const [plantNames, setPlantNames] = useState([]);
  const [selectedPlants, setSelectedPlants] = useState([null]);
  const [isPlantSelected, setPlantSelected] = usePlantSelection(false);
  const [isPlantNamed, setPlantNamed] = usePlantNaming(false);

  useEffect(() => {
    const fetchPlantData = async () => {
      const plantNamesData = await fetchPlantNames();
      setPlantNames(plantNamesData);
    };
    fetchPlantData().then(() => {
      setPlantPlantLocations(["Indoor", "Outdoor", "Greenhouse", "Hydroponic"]);
    });
  }, []);

  const handlePlantSelectionChange = (newInputValue, optionId) => {
    console.log("newInputValue: ", newInputValue);
    console.log("optionId: ", optionId);
    const plantExists = plantNames.some(
      (plant) => plant.name === newInputValue,
    );
    console.log("plantExists: ", plantExists);
    console.log("plantData: ", plantData);

    // Check if plantData.name has already been set
    if (!plantData.name) {
      setPlantData({
        ...plantData,
        name: newInputValue,
        plantId: plantExists ? optionId : null,
        newPlantName: plantExists ? null : newInputValue,
      });
    } else {
      setPlantData({
        ...plantData,
        plantId: plantExists ? optionId : null,
        newPlantName: plantExists ? null : newInputValue,
      });
    }

    setPlantSelected(true);
    setPlantNamed(true);
  };

  // const handlePlantChange = (newValue) => {
  //   if (typeof newValue === "string") {
  //     handlePlantSelectionChange(newValue);
  //   } else if (newValue !== null) {
  //     setPlantData({
  //       ...plantData,
  //       name: newValue.name,
  //       plantId: newValue.id,
  //       newPlantName: null,
  //     });
  //     setSelectedPlants([newValue]);
  //   } else {
  //     setPlantData({
  //       ...plantData,
  //       name: "",
  //       plantId: null,
  //       newPlantName: null,
  //     });
  //     setSelectedPlants([null]);
  //   }
  //   setPlantSelected(newValue !== "");
  // };

  return (
    <div>
      <Autocomplete
        freeSolo
        options={plantNames}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        value={
          plantNames.find((option) => option.id === plantData.plantId) || null // Provide a default value
        }
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            margin="dense"
            name="name"
            label="What are you growing?"
            type="text"
            fullWidth
            key={params.inputProps.id || params.inputProps.name}
          />
        )}
        onChange={(event, newValue) => {
          let newInputValue = "";
          let optionId = null;
          if (typeof newValue === "string") {
            newInputValue = newValue;
          } else if (newValue !== null) {
            newInputValue = newValue.name;
            optionId = newValue.id;
          }
          handlePlantSelectionChange(newInputValue, optionId);
        }}
      />

      <TextField
        margin="dense"
        name="name"
        label="Name your plant"
        type="text"
        fullWidth
        value={plantData.name}
        disabled={!isPlantSelected}
        onChange={(event) => {
          setPlantData({
            ...plantData,
            name: event.target.value,
          });
          setPlantNamed(event.target.value !== "");
        }}
        InputLabelProps={{
          style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        style={{ marginBottom: "20px" }}
      />
      <Autocomplete
        freeSolo
        options={plantLocations}
        getOptionLabel={(option) => option}
        disabled={!isPlantNamed}
        value={plantData.location} // Set the value to plantData.location
        renderInput={(params) => (
          <TextField
            {...params}
            margin="dense"
            name="location"
            label="Where are you growing?"
            type="text"
            fullWidth
          />
        )}
        onInputChange={(event, newInputValue) => {
          setPlantData({
            ...plantData,
            location: newInputValue,
          });
        }}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setPlantData({
              ...plantData,
              location: newValue,
            });
          } else if (newValue !== null) {
            setPlantData({
              ...plantData,
              location: newValue,
            });
          }
          console.log("plantData location: ", plantData);
        }}
      />
    </div>
  );
}

export default PlantDetailsStep;
