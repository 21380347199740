export default function Events() {
  // upcoming events to look out for your plants
  let events = [
    {
      id: 1,
      title: "",
      eventType: "Succession Planting",
      eventTriggerDate: "",
      eventDate: "",
      content:
        "Start seeds indoors 6-8 weeks before the last frost date. Transplant seedlings outdoors 2-3 weeks after the last frost date.",
      plantTagId: 1,
    },
    {
      id: 2,
      title: "",
      eventType: "Start Fertilizing",
      eventTriggerDate: "",
      eventDate: "",
      content:
        "You should consider fertilizing your {plant}.  Fertilize {plant} every 2 weeks with a balanced fertilizer.",
      plantTagId: 1,
    },
    {
      id: 3,
      title: "",
      eventType: "Start Fertilizing",
      eventTriggerDate: "",
      eventDate: "",
      content:
        "You should consider fertilizing your {plant}.  Fertilize {plant} every 2 weeks with a balanced fertilizer.",
      plantTagId: 2,
    },
    {
      id: 4,
      title: "",
      eventType: "Start Fertilizing",
      eventTriggerDate: "",
      eventDate: "",
      content:
        "You should consider fertilizing your {plant}.  Fertilize {plant} every 2 weeks with a balanced fertilizer.",
      plantTagId: 3,
    },
    {
      id: 5,
      title: "",
      eventType: "Start Fertilizing",
      eventTriggerDate: "",
      eventDate: "",
      content:
        "You should consider fertilizing your {plant}.  Fertilize {plant} every 2 weeks with a balanced fertilizer.",
      plantTagId: 4,
    },
    {
      id: 6,
      title: "",
      eventType: "Start Fertilizing",
      eventTriggerDate: "",
      eventDate: "",
      content:
        "You should consider fertilizing your {plant}.  Fertilize {plant} every 2 weeks with a balanced fertilizer.",
      plantTagId: 5,
    },
    {
      id: 7,
      title: "",
      eventType: "Start Fertilizing",
      eventTriggerDate: "",
      eventDate: "",
      content:
        "You should consider fertilizing your {plant}.  Fertilize {plant} every 2 weeks with a balanced fertilizer.",
      plantTagId: 6,
    },
    {
      id: 8,
      title: "",
      eventType: "Start Fertilizing",
      eventTriggerDate: "",
      eventDate: "",
      content:
        "You should consider fertilizing your {plant}.  Fertilize {plant} every 2 weeks with a balanced fertilizer.",
      plantTagId: 7,
    },
    {
      id: 9,
      title: "",
      eventType: "Start Fertilizing",
      eventTriggerDate: "",
      eventDate: "",
      content:
        "You should consider fertilizing your {plant}.  Fertilize {plant} every 2 weeks with a balanced fertilizer.",
      plantTagId: 8,
    },
  ];

  return (
    <div>
      <h1>Events</h1>
      {events.map((event) => (
        <div key={event.id}>
          <h2>{event.title}</h2>
          <p>{event.content}</p>
          <p>{event.eventType}</p>
          <p>{event.eventTriggerDate}</p>
          <p>{event.eventDate}</p>
          <p>{event.plantTagId}</p>
        </div>
      ))}
    </div>
  );
}
