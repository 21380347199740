import { createBrowserRouter } from "react-router-dom";
import PlantTagList from "../pages/plant/PlantTagList/PlantTagList";
import MySeedVault from "../pages/plant/MySeedVault/MySeedVault";
import PlantInfoPage from "../pages/plant/PlantInfoPage";
import Signup from "../pages/signup/Signup";
import Splash from "../pages/splash/Splash";
import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../pages/login/Login";
import Main from "../pages/dashboard/Main";
import PlantLogDialog from "../pages/plant/PlantLogDialog";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Splash />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        path: "",
        element: <Main />,
      },
      {
        path: "growtags",
        element: <PlantTagList />,
      },
      {
        path: "myseedvault",
        element: <MySeedVault />,
      },
      {
        path: "seedstore",
      },
      {
        path: "settings",
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/growtag/:id",
    element: <PlantInfoPage />,
    children: [
      {
        path: "add-journal-entry",
        element: <PlantLogDialog />,
      },
    ],
  },
]);

export default routes;
