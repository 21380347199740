import React, { useState } from "react";
import Tooltip from "react-portal-tooltip";

function PreviewLink({ url }) {
  const [isTooltipActive, setIsTooltipActive] = useState(true);

  const showTooltip = () => {
    setIsTooltipActive(true);
  };

  const hideTooltip = () => {
    setIsTooltipActive(false);
  };

  return (
    <div>
      <a
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        Hover to preview
      </a>
      <Tooltip active={isTooltipActive} position="right" arrow="center">
        <iframe src={url} width="500" height="500" title="Preview" />
      </Tooltip>
    </div>
  );
}

export default PreviewLink;
