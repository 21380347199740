import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import React from "react";

function PlantDetailsAccordion(props) {
  const plantDto = props.plantInfo.plantDto || {};

  // Function to format date
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Accordion expanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography variant="h6" style={{ padding: "8px" }}>
          Plant Details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Plant Name"
              value={plantDto.name || ""}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              label="Description"
              value={plantDto.description || ""}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{
                readOnly: true,
              }}
              multiline
              rowsmax="100" // Set to a large number
            />
            <TextField
              label="Growing Tips"
              value={plantDto.growingTips || ""}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{
                readOnly: true,
              }}
              multiline
              rowsmax="100" // Set to a large number
            />
            <TextField
              label="Organic"
              value={props.plantInfo.organic ? "Yes" : "No"}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              label="Date of Planting"
              value={formatDate(props.plantInfo.plantedDate) || ""}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{
                readOnly: true,
              }}
            />
            {plantDto.seedManufacturer && (
              <TextField
                label="Seed Manufacturer"
                value={plantDto.seedManufacturer || ""}
                variant="outlined"
                fullWidth
                size="small"
                style={{ margin: "8px 0" }}
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
            <TextField
              label="Is Clone"
              value={props.plantInfo.clone ? "Yes" : "No"}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              label="Plant Type"
              value={plantDto.plantType || ""}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Ideal Temperature"
              value={plantDto.idealTemp || ""}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Ideal PH Range"
              value={
                plantDto
                  ? (plantDto.phStartRange || "") +
                    " - " +
                    (plantDto.phEndRange || "")
                  : ""
              }
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Seed Depth"
              value={plantDto.seedDepth || ""}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Plant Spacing"
              value={plantDto.seedSpacing || ""}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Sprouts In"
              value={plantDto.sproutTime || ""}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{ readOnly: true }}
            />
            <TextField
              label="Hours of Sunlight"
              value={plantDto.hoursOfSunlight || ""}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{ readOnly: true }}
            />
            {plantDto.daysToMaturity && (
              <TextField
                label="Days to Maturity"
                value={plantDto.daysToMaturity || ""}
                variant="outlined"
                fullWidth
                size="small"
                style={{ margin: "8px 0" }}
                InputProps={{ readOnly: true }}
              />
            )}
            <TextField
              label="Frost Tolerance"
              value={plantDto.frostTolerance || ""}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{ readOnly: true }}
            />
            {plantDto.fruitSize && (
              <TextField
                label="Fruit Size"
                value={plantDto.fruitSize}
                variant="outlined"
                fullWidth
                size="small"
                style={{ margin: "8px 0" }}
                InputProps={{ readOnly: true }}
              />
            )}
            {plantDto.commonPests && (
              <TextField
                label="Common Pests"
                value={plantDto.commonPests || ""}
                variant="outlined"
                fullWidth
                size="small"
                style={{ margin: "8px 0" }}
                InputProps={{ readOnly: true }}
              />
            )}
            {plantDto.commonDiseases && (
              <TextField
                label="Common Diseases"
                value={plantDto.commonDiseases || ""}
                variant="outlined"
                fullWidth
                size="small"
                style={{ margin: "8px 0" }}
                InputProps={{ readOnly: true }}
              />
            )}
            <TextField
              label="Species"
              value={plantDto.species || ""}
              variant="outlined"
              fullWidth
              size="small"
              style={{ margin: "8px 0" }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default PlantDetailsAccordion;
