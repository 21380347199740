import { Grid } from "@material-ui/core";
import PlantDetailsAccordion from "./PlantDetailsAccordion";
import GrowthAccordion from "./GrowthAccordion";
import * as PropTypes from "prop-types";
import React from "react";

export default function DetailsTab(props) {
  console.log("DetailsTab", props.plantInfo);
  return (
    <Grid item xs={12} style={{ padding: "0 16px" }}>
      <PlantDetailsAccordion plantInfo={props.plantInfo} />
      {/*<GrowthAccordion plantInfo={props.plantInfo} />*/}
    </Grid>
  );
}

DetailsTab.propTypes = {
  plantInfo: PropTypes.shape({
    isGrowTent: PropTypes.bool,
    isShadeCloth: PropTypes.bool,
    sproutsIn: PropTypes.string,
    avoidGrowingWith: PropTypes.string,
    isOrganic: PropTypes.bool,
    description: PropTypes.string,
    isContainer: PropTypes.bool,
    isSquareFoot: PropTypes.bool,
    isIndoor: PropTypes.bool,
    harvestInstructions: PropTypes.string,
    isAeroponic: PropTypes.bool,
    isAquaponic: PropTypes.bool,
    isHugelkultur: PropTypes.bool,
    fruitSize: PropTypes.string,
    growingMethod: PropTypes.string,
    isOutdoor: PropTypes.bool,
    seedManufacturer: PropTypes.string,
    commonDiseases: PropTypes.string,
    howToGrow: PropTypes.string,
    growthStage: PropTypes.string,
    companionPlants: PropTypes.string,
    faq: PropTypes.any,
    imageUrl: PropTypes.string,
    plantSpacing: PropTypes.string,
    isClone: PropTypes.bool,
    matureHeight: PropTypes.string,
    commonPests: PropTypes.string,
    ec: PropTypes.string,
    isHydroponic: PropTypes.bool,
    dateOfPlanting: PropTypes.string,
    idealTemperature: PropTypes.string,
    ppm: PropTypes.string,
    isRaisedBed: PropTypes.bool,
    isBackToEden: PropTypes.bool,
    plantType: PropTypes.string,
    seedDepth: PropTypes.string,
    nfcTag: PropTypes.string,
    daysToMaturity: PropTypes.string,
    isColdFrame: PropTypes.bool,
    isVertical: PropTypes.bool,
    species: PropTypes.string,
    name: PropTypes.string,
    ph: PropTypes.string,
    isGreenhouse: PropTypes.bool,
    growMedium: PropTypes.string,
    minFullSunHours: PropTypes.string,
  }),
};
