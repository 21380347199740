import { useState } from "react";

export const usePlantNaming = (initialValue) => {
  const [isPlantNamed, setIsPlantNamed] = useState(initialValue);

  const handlePlantNaming = (newValue) => {
    setIsPlantNamed(newValue !== "");
  };

  return [isPlantNamed, handlePlantNaming];
};
