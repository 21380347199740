import React from "react";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { Box, Typography } from "@mui/material";

export default function Weather() {
  const weather = {
    title: "Sunny",
    temperature: 80,
    humidity: 50,
    windSpeed: 10,
    uvIndex: 5,
    precipitation: 0,
    precipitationProbability: 0,
    visibility: 10,
    sunrise: "6:00 AM",
    sunset: "6:00 PM",
    alert: "Tropical Storm Warning in effect",
  };

  return (
    <Box
      sx={{
        width: 200,
        padding: 2,
        borderRadius: 1,
        boxShadow: 1,
        bgcolor: "background.paper",
      }}
    >
      <Typography variant="h6" gutterBottom component="div">
        Weather
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
        <WbSunnyIcon sx={{ fontSize: 40, marginRight: 1 }} />
        <Typography variant="h5">{weather.title}</Typography>
      </Box>
      <Typography variant="body2">
        Temperature: {weather.temperature} degrees
      </Typography>
      <Typography variant="body2">Humidity: {weather.humidity}%</Typography>
      <Typography variant="body2">
        Wind Speed: {weather.windSpeed} mph
      </Typography>
      <Typography variant="body2">UV Index: {weather.uvIndex}</Typography>
      <Typography variant="body2">
        Precipitation: {weather.precipitation} mm
      </Typography>
      <Typography variant="body2">
        Precipitation Probability: {weather.precipitationProbability}%
      </Typography>
      <Typography variant="body2">
        Visibility: {weather.visibility} miles
      </Typography>
      <Typography variant="body2">Sunrise: {weather.sunrise}</Typography>
      <Typography variant="body2">Sunset: {weather.sunset}</Typography>
      <Typography variant="body2">Alert: {weather.alert}</Typography>
    </Box>
  );
}
