import React, { useEffect } from "react";
import { Button, Typography, TextField, Box } from "@mui/material";
import { styled } from "@mui/system";
import backgroundImage from "./bg.jpg"; // Import your background image
import { useNavigate } from "react-router-dom";

const Nav = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px 40px",
  borderBottom: "1px solid #eee",
  background: "#fff", // Set a white background for the Nav
});

const MainContent = styled("div")({
  position: "relative", // Add position relative to enable full height
  width: "100%",
  minHeight: "100vh", // Make sure the content stretches to full viewport height
  background: `url(${backgroundImage}) no-repeat center center fixed`,
  backgroundSize: "cover", // Ensure the background covers the entire viewport
  display: "flex", // Use Flexbox for centering
  alignItems: "center", // Center vertically
  justifyContent: "center", // Center horizontally
});

const ContentWrapper = styled("div")({
  background: "rgba(255, 255, 255, 0.9)", // Adjust the background color as needed
  padding: "20px",
  borderRadius: "8px", // Add styling for the content wrapper
});

function SplashPage() {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://storage.ko-fi.com/cdn/scripts/overlay-widget.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.kofiWidgetOverlay.draw("growtags", {
        type: "floating-chat",
        "floating-chat.donateButton.text": "Support Us",
        "floating-chat.donateButton.background-color": "#00b9fe",
        "floating-chat.donateButton.text-color": "#fff",
      });
    };
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box>
      <Nav>
        <Typography variant="h4" color="textPrimary">
          Grow Tags
        </Typography>
        <div>
          <Button variant="contained" color="primary" onClick={handleLogin}>
            Login
          </Button>
        </div>
      </Nav>

      {/* Main Content */}
      <MainContent>
        <ContentWrapper>
          <Typography variant="h2" gutterBottom color="textPrimary">
            Plant Labels with Built-in NFC
          </Typography>

          <Typography variant="body1" gutterBottom color="textSecondary">
            Welcome to Grow Tags, the ultimate solution for plant enthusiasts.
            Our innovative plant labels come with built-in NFC technology,
            allowing you to easily scan and manage your plants.
          </Typography>

          <Typography variant="body1" gutterBottom color="textSecondary">
            With Grow Tags, you can log information about each plant, receive
            valuable insights, and connect with other users for advice and
            assistance. Whether you're a seasoned gardener or just starting,
            Grow Tags has you covered.
          </Typography>

          <Box marginY={4}>
            <TextField
              label="First Name"
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <TextField
              label="Last Name"
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <Button variant="contained" color="primary" fullWidth>
              JOIN THE BETA TEST
            </Button>
          </Box>
        </ContentWrapper>
      </MainContent>

      {/* Illustrations would be positioned using CSS and added to the page */}
    </Box>
  );
}

export default SplashPage;
