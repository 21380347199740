import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { FaBug, FaTint, FaSeedling, FaCut, FaApple } from "react-icons/fa";
import { CircularProgress, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import AxiosInstance from "../../startup/startup";

const PlantLogDialog = ({ open, onClose, onSave, selectedLog, onDelete }) => {
  const [newLog, setNewLog] = useState({
    id: "",
    type: "",
    logTypeDescription: "",
    notes: "",
    treatment: "",
    treatmentNote: "",
    treatmentId: "",
    logDate: "",
  });
  const [logs, setLogs] = useState([]);
  const [availableDescriptions, setAvailableDescriptions] = useState([]);
  const [pestInfo, setPestInfo] = useState({ images: [], description: "" });
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  // New states for Treatment dialog and selected treatment
  const [openTreatmentDialog, setOpenTreatmentDialog] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState("");
  const [customTreatment, setCustomTreatment] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Add Journal Entry");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = () => {
    AxiosInstance.delete(`/user-grow-tag/delete-journal-entry/${newLog.id}`)
      .then((response) => {
        setOpenDeleteDialog(false);
        onClose();
        onDelete(newLog); // Call onDelete prop after deleting a log
      })
      .catch((error) => {
        console.error("Error deleting log:", error);
      });
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const logTypes = [
    "Pest",
    "Water",
    "Fertilizer",
    "Pruning",
    "Harvest",
    "Cross-breed",
    "Journal",
  ];

  const descriptions = {
    Pest: ["Aphids", "Whiteflies", "Spider Mites", "Leaf Miners", "Slugs"],
    Water: ["Watered", "Misted", "Flooded"],
    Fertilizer: ["NPK 20-20-20", "Bone Meal", "Fish Emulsion"],
    Pruning: ["Light Pruning", "Heavy Pruning", "Deadheading"],
  };

  useEffect(() => {
    setAvailableDescriptions(descriptions[newLog.type] || []);
  }, [newLog.type]);

  const logTypeIcons = {
    Pest: <FaBug />,
    Water: <FaTint />,
    Fertilizer: <FaSeedling />,
    Pruning: <FaCut />,
    Harvest: <FaApple />,
  };

  const pestDetails = {
    Aphids: {
      images: [
        "https://www.greenhousegrower.com/wp-content/uploads/2016/07/Aphids-On-Older-Leaves.jpg",
        "https://www.thoughtco.com/thmb/9NV0xuysuHqGNLsb132gq_bVWe4=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/ant-aphids-5910c85d5f9b586470279349-3416afd1e9f34dd59078886872ac8c17.jpg",
      ],
      description:
        "Aphids are small, soft-bodied insects that feed on plants. They can be green, yellow, brown, or black in color. They are often found on the undersides of leaves and stems. To control them, use insecticidal soap or neem oil.",
    },
    "Spider Mites": {
      images: [
        "https://images.immediate.co.uk/production/volatile/sites/10/2018/02/e874b40d-9595-4689-823c-aa6974ee3d73-07c58af.jpg?quality=90&resize=940,627",
        "https://www.tulsamastergardeners.org/blog/ewExternalFiles/spider%20mites.jpg",
      ],
      description:
        "Spider mites are tiny insects that feed on plants. They can be red, yellow, brown, or black in color. They are often found on the undersides of leaves and stems. To control them, use insecticidal soap or neem oil.",
    },
    Whiteflies: {
      images: [
        "https://dropinblog.net/34251073/files/featured/Prevent_Whiteflies.png",
        "https://www.planetnatural.com/wp-content/uploads/2012/12/whitefly.jpg",
      ],
      description:
        "Whiteflies are tiny insects that can damage plants. They excrete sticky honeydew and cause yellowing of leaves. To control them, use insecticidal soap or neem oil.",
    },
    "Leaf Miners": {
      images: [
        "https://gardenerspath.com/wp-content/uploads/2021/06/How-to-Control-Leaf-Miners-FB.jpg",
        "https://gardenerspath.com/wp-content/uploads/2021/06/Adult-fly-stage-of-leaf-miner-Amauromyza-flavifrons.jpg",
      ],
      description:
        "Leaf miners are insects that eat the leaf tissue of plants. The larvae of these insects live and feed within the leaves, creating tunneling injuries. \n" +
        "Leaf miners include: Moths, Sawflies, Flies, Beetles, Wasps. \n" +
        "Leaf miners are attracted to: \n" +
        "Garden foliage\n" +
        "Leaves of junipers\n" +
        "Leaves of arborvitae\n" +
        "Leaves of birches\n" +
        "Leaf miners can cause: \n" +
        "Loss of healthy leaf tissue\n" +
        "Plants can't capture enough sunlight\n" +
        "Plants often become infected with disease\n" +
        "Plants often fail to grow or produce crops\n" +
        "Some treatments for leaf miners include: \n" +
        "Spinosad\n" +
        "Broad spectrum pesticides\n" +
        "Natural pyrethrin concentrate\n" +
        "To treat leaf miners, you can: \n" +
        "Apply pesticides in the spring when adult flies are first active\n" +
        "Make sure you get good coverage on the leaves\n" +
        "Remove any leaves that show evidence of leaf minor damage\n" +
        "Prune off any branches that are showing evidence of leaf minor damage",
    },
    Slugs: {
      images: [
        "https://images.seattletimes.com/wp-content/uploads/2018/04/021d7806-3386-11e8-9316-bc6406bfcffc.jpg?d=780x520",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMIChOq2oxXyQ1nekB-luHYBJx5pIPc-Rhzg&usqp=CAU",
      ],
      description:
        "Garden slugs are small, air-breathing land mollusks. They are usually gray, dull-orange, or dark brown, and can be 1 to 3 inches long. They can crawl up to 25 feet in an hour. \n" +
        "Garden slugs are present from spring to fall in cool, moist, shady areas. They eat the leaves of many plants, especially seedlings, as well as ripening fruits and vegetables, and decaying plant matter. Extensive feeding can cause plants to become weak or die. \n" +
        "The most common garden slug is the gray garden slug, which is usually less than 1 inch long. The leopard slug is the largest, and can be 4 to 5 inches long. \n" +
        "Garden slugs have a lifespan of 7.5 to 12 months. Females lay 158 to 205 eggs. They increase in size during the summer and reach sexual maturity in the autumn and winter. They only reproduce in the early months of the following spring. ",
    },
  };

  useEffect(() => {
    setAvailableDescriptions(descriptions[newLog.type] || []);
  }, [newLog.type]);

  useEffect(() => {
    if (newLog.type === "Pest" && pestDetails[newLog.logTypeDescription]) {
      setPestInfo(pestDetails[newLog.logTypeDescription]);
    } else {
      setPestInfo({ images: [], logTypeDescription: "" });
    }
  }, [newLog.logTypeDescription]);

  useEffect(() => {
    if (selectedLog) {
      console.log("Selected Log:", selectedLog);
      // Convert the type to match the casing in the component
      const type = selectedLog.type.toLowerCase();
      const formattedType = type.charAt(0).toUpperCase() + type.slice(1);
      setNewLog({ ...selectedLog, type: formattedType });
      console.log("use effect new log:", newLog);
      setDialogTitle("Edit Log");
    } else {
      setNewLog({
        id: "",
        type: "",
        logTypeDescription: "",
        notes: "",
        treatment: "",
        treatmentNote: "",
        treatmentId: "",
        logDate: "",
      });
      setDialogTitle("Add Journal Entry");
    }
  }, [selectedLog]);

  useEffect(() => {
    console.log("Updated new log:", newLog);
  }, [newLog]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("Name:", name, "Value:", value);
    setNewLog({ ...newLog, [name]: value });
    console.log("New Log:", newLog);
  };

  const handleSave = () => {
    setIsSaving(true);
    // Simulate saving process
    setTimeout(() => {
      handleSaveLog(newLog);
      setIsSaving(false);
      setNewLog({
        id: "",
        type: "",
        logTypeDescription: "",
        notes: "",
        treatment: "",
        treatmentNote: "",
        treatmentId: "",
        logDate: "",
      });
    }, 2000); // 2 seconds delay
  };

  let { id } = useParams();

  const handleSaveLog = (newLog) => {
    const now = new Date();
    newLog.logDate = now.toISOString();
    newLog.type = newLog.type.toUpperCase();
    setLogs([newLog, ...logs]); // Place the new log at the beginning
    if (!selectedLog) {
      const url = `/user-grow-tag/${id}/add-journal-entry`;
      AxiosInstance.post(url, newLog)
        .then((response) => {
          // Handle response here
          console.log(response.data);
          setIsSaving(false);
          setNewLog({
            id: "",
            type: "",
            logTypeDescription: "",
            notes: "",
            treatment: "",
            treatmentNote: "",
            treatmentId: "",
            logDate: "",
          });
          onSave(newLog);
          onClose(false);
        })
        .catch((error) => {
          // Handle error here
          console.log(error);
          setIsSaving(false);
        });
    } else if (selectedLog) {
      const url = `/user-grow-tag/edit-journal-entry`;
      AxiosInstance.patch(url, newLog)
        .then((response) => {
          // Handle response here
          console.log(response.data);
          setIsSaving(false);
          setNewLog({
            id: "",
            type: "",
            logTypeDescription: "",
            notes: "",
            treatment: "",
            treatmentNote: "",
            treatmentId: "",
            logDate: "",
          });
          onSave(newLog);
          onClose(false);
        })
        .catch((error) => {
          // Handle error here
          console.log(error);
          setIsSaving(false);
        });
    }

    onSave(newLog);
    onClose(false);
  };

  const handleImageClick = (imgSrc) => {
    setSelectedImage(imgSrc);
    setOpenImageDialog(true);
  };

  // Suggested treatments and their descriptions
  const treatments = {
    NeemOil:
      "Neem oil is a natural pesticide that can be used to treat a variety of pests. It acts as a repellent and disrupts the life cycle of pests.",
    InsecticidalSoap:
      "Insecticidal soap is a safe and effective way to kill pests on contact. It works by dissolving the protective outer layer of insects.",
    YellowStickyTraps:
      "Yellow sticky traps are a non-toxic way to control pests. They attract insects with their bright color and sticky surface.",
    BiologicalControl:
      "Biological control is a method of pest control that uses natural enemies to reduce the population of pests. It can be used to control a wide range of pests, including insects, mites, and weeds.",
    DiatomaceousEarth:
      "Diatomaceous earth is a natural product that can be used to control pests. It is made from the fossilized remains of diatoms, a type of algae.",
    CompanionPlanting:
      "Companion planting is a method of planting different crops together to benefit each other. It can be used to control pests, improve soil fertility, and increase crop yields.",
    CropRotation:
      "Crop rotation is a method of planting different crops in the same field in different years. It can be used to control pests, improve soil fertility, and increase crop yields.",
    Pruning:
      "Pruning is a method of removing dead or diseased branches from a plant. It can be used to control pests, improve soil fertility, and increase crop yields.",
  };

  // Handler for opening the Treatment dialog
  const handleTreatProblemClick = () => {
    setOpenTreatmentDialog(true);
  };

  // Handler for the Treat button in the Treatment dialog
  const handleTreat = () => {
    setOpenTreatmentDialog(false);
    setNewLog({ ...newLog, treatment: selectedTreatment || customTreatment });
  };

  const handleTreatmentCancel = () => {
    setSelectedTreatment("");
    setCustomTreatment("");
    setNewLog({ ...newLog, treatment: "" });
    setNewLog({ ...newLog, treatmentNote: "" });
    setOpenTreatmentDialog(false);
  };

  const handleCancel = () => {
    setNewLog({
      id: "",
      type: "",
      logTypeDescription: "",
      notes: "",
      treatment: "",
      treatmentNote: "",
      treatmentId: "",
      logDate: "",
    });
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <InputLabel>Type</InputLabel>
          <Select
            name="type"
            value={newLog.type}
            onChange={handleChange}
            fullWidth
            style={{ marginBottom: "20px" }} // Add margin bottom
          >
            {logTypes.map((type) => (
              <MenuItem value={type} key={type}>
                {logTypeIcons[type]} - {type}
              </MenuItem>
            ))}
          </Select>
          {newLog.type !== "Journal" && (
            <>
              <InputLabel>Options</InputLabel>
              <Select
                name="logTypeDescription"
                value={newLog.logTypeDescription}
                onChange={handleChange}
                fullWidth
                style={{ marginBottom: "20px" }} // Add margin bottom
              >
                {availableDescriptions.map((desc) => (
                  <MenuItem value={desc} key={desc}>
                    {desc}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          {pestInfo.images.length > 0 && (
            <div>
              {pestInfo.images.map((imgSrc, index) => (
                <img
                  key={index}
                  src={imgSrc}
                  alt="Pest Image"
                  style={{ width: "40%", margin: "10px", cursor: "pointer" }}
                  onClick={() => handleImageClick(imgSrc)}
                />
              ))}
              <div>{pestInfo.description}</div>
              {newLog.type === "Pest" && (
                <Button color="secondary" onClick={handleTreatProblemClick}>
                  Treat Problem
                </Button>
              )}
            </div>
          )}
          {logs.treatment && (
            <p>
              Treated with: {logs.treatment}
              {logs.treatmentNote && (
                <span> - Treatment Note: {logs.treatmentNote}</span>
              )}
            </p>
          )}
          <TextField
            name="notes"
            value={newLog.notes}
            onChange={handleChange}
            multiline
            minRows={4} // Changed from 'rows' to 'minRows'
            variant="outlined"
            fullWidth
            label="Journal Entry"
            placeholder="Type your journal entry here..."
            style={{ marginTop: "20px" }} // Add margin top
          />
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              {selectedLog && (
                <Button onClick={handleDelete} color="secondary">
                  Delete
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                onClick={handleCancel}
                color="primary"
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button onClick={handleSave} color="primary" disabled={isSaving}>
                {isSaving ? <CircularProgress size={24} /> : "Save"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this log?</DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Image Dialog */}
      <Dialog open={openImageDialog} onClose={() => setOpenImageDialog(false)}>
        <DialogTitle>View Image</DialogTitle>
        <DialogContent>
          <img src={selectedImage} alt="Pest" style={{ width: "100%" }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenImageDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Treatment Dialog */}
      <Dialog
        open={openTreatmentDialog}
        onClose={() => setOpenTreatmentDialog(false)}
      >
        <DialogTitle>Treat Problem</DialogTitle>
        <DialogContent>
          <InputLabel>Treatment</InputLabel>
          <Select
            value={selectedTreatment}
            onChange={(e) => setSelectedTreatment(e.target.value)}
            fullWidth
          >
            {Object.keys(treatments).map((treatment) => (
              <MenuItem value={treatment} key={treatment}>
                {treatment}
              </MenuItem>
            ))}
            <MenuItem value="Other">Other</MenuItem>
          </Select>
          {selectedTreatment === "Other" && (
            <TextField
              label="Specify Treatment"
              value={customTreatment}
              onChange={(e) => setCustomTreatment(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}
          {treatments[selectedTreatment] && (
            <p>{treatments[selectedTreatment]}</p>
          )}
          <TextField
            multiline={true}
            fullWidth
            label="Add Treatment Note"
            value={newLog.treatmentNote}
            onChange={(e) =>
              setNewLog({ ...newLog, treatmentNote: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleTreatmentCancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={handleTreat} color="primary">
            Treat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlantLogDialog;
