import axios from "axios";

let baseURL;

if (window.location.hostname === "localhost") {
  baseURL = "http://localhost:8080/api";
} else {
  baseURL = `http://${window.location.hostname}:8080/api`;
}

const AxiosInstance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default AxiosInstance;
