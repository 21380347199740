import Button from "@mui/material/Button";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  Alert,
} from "@mui/lab";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { FaBug, FaCut, FaSeedling, FaTint } from "react-icons/fa";
import PlantLogDialog from "./PlantLogDialog";
import { Grid } from "@material-ui/core";
import AxiosInstance from "../../startup/startup";
import { useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";

function JournalTab(props) {
  const [logs, setLogs] = useState([]);
  const [logDialogOpen, setLogDialogOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleAddLog = () => {
    setSelectedLog(null); // Reset selected log to null
    setLogDialogOpen(true);
  };

  const handleEditLog = (log) => {
    setSelectedLog(log);
    setLogDialogOpen(true);
  };

  let { id } = useParams();

  useEffect(() => {
    AxiosInstance.get(`/user-grow-tag/${id}/journal-entries`)
      .then((response) => {
        console.log("Journal entries:", response.data);
        setLogs(response.data);
      })
      .catch((error) => {
        // TODO throw error on screen
      });
  }, []);

  const handleSaveLog = (newLog) => {
    const now = new Date();
    newLog.dateTime = now.toISOString();

    // Check if the log is new or edited
    if (newLog.id) {
      // This is an edited log
      // Find the log in the logs array and update it
      const updatedLogs = logs.map((log) => {
        if (log.id === newLog.id) {
          return newLog;
        } else {
          return log;
        }
      });

      // Set the state with the updated logs array
      setLogs(updatedLogs);
    } else {
      // This is a new log
      // Add the new log at the beginning of the logs array
      setLogs([newLog, ...logs]);
    }

    setLogDialogOpen(false);
    setSnackbarMessage("Log saved successfully");
    setSnackbarOpen(true);
  };

  const handleDeleteLog = (deletedLog) => {
    // Remove the log from the logs array
    const updatedLogs = logs.filter((log) => log.id !== deletedLog.id);
    setLogs(updatedLogs);
    setSnackbarMessage("Log deleted successfully");
    setSnackbarOpen(true);
  };

  const formatDate = (dateString) => {
    // Check if dateString is a valid date
    const date = new Date(Date.parse(dateString));
    if (isNaN(date.getTime())) {
      // dateString is not a valid date
      console.error("Invalid date:", dateString);
      return null;
    }
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    return formatter.format(date);
  };

  const handleClose = () => {
    setLogDialogOpen(false);
    setSelectedLog(null); // Reset selected log to null
  };
  const iconMapper = (type) => {
    switch (type) {
      case "Water":
        return <FaTint />;
      case "Fertilizer":
        return <FaSeedling />;
      case "Pruning":
        return <FaCut />;
      case "Pest":
        return <FaBug />;
      default:
        return null;
    }
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Button onClick={handleAddLog} style={{ margin: "16px" }}>
        Add Journal Entry
      </Button>
      <Timeline>
        {logs.map((log, index) => (
          <TimelineItem
            key={index}
            position={index % 2 === 0 ? "left" : "right"}
          >
            <TimelineSeparator>
              <TimelineDot>{iconMapper(log.type)}</TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="subtitle2" color="textSecondary">
                {formatDate(log.logDate)}
              </Typography>
              <Typography variant="body1">
                {log.type}:{" "}
                {log.notes && log.notes.length > 30
                  ? log.notes.substring(0, 30) + "..."
                  : log.notes}
              </Typography>
              {log.treatment && (
                <>
                  <Typography variant="subtitle2" color={"textSecondary"}>
                    Treated with: {log.treatment}
                  </Typography>
                  {log.treatmentNote && (
                    <Typography variant="subtitle2" color={"textSecondary"}>
                      Treatment Note: {log.treatmentNote}
                    </Typography>
                  )}
                </>
              )}
              <Button
                size="small"
                onClick={() => handleEditLog(log)}
                style={{ padding: "5px", fontSize: "0.7rem" }}
              >
                View
              </Button>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      <PlantLogDialog
        open={logDialogOpen}
        onClose={() => handleClose()}
        onSave={handleSaveLog}
        onDelete={handleDeleteLog}
        selectedLog={selectedLog}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default JournalTab;
