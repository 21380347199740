export default function SocialFeed() {
  let posts = [
    {
      id: 1,
      title: "Post 1",
      content: "This is the first post",
      image: "https://picsum.photos/200/300",
      likes: 10,
      comments: [],
    },
    {
      id: 2,
      title: "Post 2",
      content: "This is the second post",
      image: "https://picsum.photos/200/300",
      likes: 0,
      comments: [
        {
          id: 1,
          content: "This is a comment",
          likes: 0,
        },
        {
          id: 2,
          content: "This is another comment",
          likes: 0,
        },
      ],
    },
  ];

  return (
    <div>
      <h1>SocialFeed</h1>
      {posts.map((post) => (
        <div key={post.id}>
          <h2>{post.title}</h2>
          <p>{post.content}</p>
          <img src={post.image} alt={post.title} />
          <p>{post.likes} likes</p>
          <button>Like</button>
          <p>Comments:</p>
          {post.comments.map((comment) => (
            <div key={comment.id}>
              <p>{comment.content}</p>
              <p>{comment.likes} likes</p>
              <button>Like</button>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
