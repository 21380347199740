import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Card,
  CardMedia,
} from "@mui/material";
import { Rating } from "@mui/lab";
import AxiosInstance from "../../startup/startup";
import { useParams } from "react-router";
import { Star, StarBorder } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";

function PlantInfoImageCard({ name, imageUrl, width }) {
  const [rating, setRating] = useState(0);
  const [prevRating, setPrevRating] = useState(0);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { id } = useParams();

  const handleClickOpen = () => {
    setPrevRating(rating); // Save the previous rating
    setOpen(true);
  };

  const handleClose = () => {
    if (rating !== prevRating) {
      // If the rating has changed, show the confirmation dialog
      setConfirmOpen(true);
    } else {
      // If the rating hasn't changed, just close the dialog
      setOpen(false);
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
    // Do not close the rating dialog
  };

  const handleCancel = () => {
    setRating(prevRating); // Reset the rating to its previous value
    setConfirmOpen(false);
    setOpen(false);
  };

  const handleSave = () => {
    AxiosInstance.post(`/user-grow-tag/rate-plant/${id}`, { rating })
      .then((response) => {
        console.log("Rating saved successfully:", response);
        setOpen(false);
        setSnackbarOpen(true); // Open the snackbar
      })
      .catch((error) => {
        console.error("Error saving rating:", error);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    AxiosInstance.get(`/user-grow-tag/get-plant-rating/${id}`)
      .then((response) => {
        console.log("Rating fetched successfully:", response.data);
        setRating(response.data.rating);
      })
      .catch((error) => {
        console.error("Error fetching rating:", error);
      });
  }, []);

  return (
    <Card>
      <CardMedia
        style={{ height: "50vh", position: "relative" }}
        image={imageUrl}
        title={name}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, .7)",
            color: "white",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "8px 8px 8px 16px",
            }}
          >
            <Typography
              variant="h6"
              style={{ textAlign: "left", marginRight: "8px" }}
            >
              {name}
            </Typography>
            <div onClick={handleClickOpen}>
              <Rating
                name="plant-rating"
                value={rating}
                readOnly
                icon={<Star fontSize="inherit" />}
                emptyIcon={
                  <StarBorder fontSize="inherit" style={{ color: "white" }} />
                }
              />
            </div>
          </div>
        </div>
      </CardMedia>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Rate Your Plant</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select a rating for your plant.
          </DialogContentText>
          <Rating
            name="dialog-rating"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>Confirm Cancel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel your changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose}>No</Button>
          <Button onClick={handleCancel}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Rating saved"
      />
    </Card>
  );
}

export default PlantInfoImageCard;
