import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import PreviewLink from "./PreviewLink";

function PlantOriginStep({ plantData, setPlantData, plantTags }) {
  const [selectedPlant, setSelectedPlant] = useState(null);

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="plantType"
        value={plantData.plantType}
        onChange={(event) =>
          setPlantData({ ...plantData, plantType: event.target.value })
        }
      >
        <FormControlLabel
          value="seed"
          control={<Radio />}
          label="Starting from Seed"
        />
        {plantData.plantType === "seed" && (
          <div style={{ marginLeft: "40px" }}>
            <TextField
              label="Seed Plant Date"
              type="date"
              value={plantData.plantedDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) =>
                setPlantData({ ...plantData, plantedDate: event.target.value })
              }
              style={{ width: "100%" }} // Add this line
            />
            {plantData.plantedDate && (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={plantData.isOrganic}
                      onChange={(event) =>
                        setPlantData({
                          ...plantData,
                          isOrganic: event.target.checked,
                        })
                      }
                    />
                  }
                  label="Is this plant organic?"
                />
              </div>
            )}
          </div>
        )}
        <FormControlLabel
          value="existing"
          control={<Radio />}
          label="Existing Plant"
        />
      </RadioGroup>
      {plantData.plantType === "existing" && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={plantData.isClone}
                onChange={(event) =>
                  setPlantData({
                    ...plantData,
                    isClone: event.target.checked,
                  })
                }
              />
            }
            label="Is this plant a clone?"
          />
          {plantData.isClone && (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="existing-plant-label">Existing Plant</InputLabel>
              <Select
                labelId="existing-plant-label"
                id="existing-plant"
                value={plantData.existingPlantId}
                onChange={(event) => {
                  const selectedPlantTag = plantTags.find(
                    (plantTag) => plantTag.growTagId === event.target.value,
                  );
                  setPlantData({
                    ...plantData,
                    existingPlantId: event.target.value,
                  });
                  setSelectedPlant(selectedPlantTag);
                }}
                label="Existing Plant"
              >
                {plantTags.map((plantTag) => (
                  <MenuItem key={plantTag.growTagId} value={plantTag.growTagId}>
                    {`${plantTag.name} - ${plantTag.location}`}
                  </MenuItem>
                ))}
              </Select>
              {selectedPlant && (
                <PreviewLink
                  url={`http://192.168.50.167:3000/growtag/${selectedPlant.nfcTagId}`}
                />
              )}
            </FormControl>
          )}
        </div>
      )}
    </FormControl>
  );
}

export default PlantOriginStep;
