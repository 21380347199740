import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AxiosInstance from "../../startup/startup";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Autocomplete } from "@mui/material";

const theme = createTheme();

export default function SignUp() {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [years, setYears] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [gender, setGender] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const currentYear = new Date().getFullYear();
  const days = Array.from({ length: 31 }, (_, i) =>
    (i + 1).toString().padStart(2, "0"),
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const navigate = useNavigate();

  const fileInputRef = useRef();

  const handleUploadClick = (event) => {
    fileInputRef.current.click();
  };

  const showAlert = (message, severity) => {
    setAlert({ open: true, message: message, severity: severity });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ ...alert, open: false });
  };

  useEffect(() => {
    const years = Array.from({ length: 121 }, (_, i) => currentYear - i).slice(
      0,
      120 + 1,
    );
    setYears(years);
  }, []);

  useEffect(() => {
    if (month && day) {
      const years = Array.from(
        { length: 121 },
        (_, i) => currentYear - i,
      ).slice(0, 120 + 1);
      const today = new Date();
      const selectedDate = new Date(currentYear, months.indexOf(month), day);
      let age = today.getFullYear() - selectedDate.getFullYear();
      const m = today.getMonth() - selectedDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < selectedDate.getDate())) {
        age--;
      }
      if (age < 18) {
        years.splice(0, 18 - age);
      }
      setYears(years);
    }
  }, [month, day]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!firstName || !lastName) {
      showAlert("First name and last name are required.", "warning");
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      showAlert("Invalid email address.", "warning");
      return;
    }
    if (password.length < 8 || !/[!@#$%^&*]/.test(password)) {
      showAlert(
        "Password must be at least 8 characters and contain at least one special character.",
        "warning",
      );
      return;
    }
    if (username.length < 6) {
      showAlert("Username must be at least 6 characters.", "warning");
      return;
    }
    if (password !== verifyPassword) {
      showAlert("Passwords do not match.", "warning");
      return;
    }
    if (!day || !month || !year) {
      showAlert("Birthday is required.", "warning");
      return;
    }
    const birthday = new Date(year, months.indexOf(month), day);
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    const m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }
    if (age < 18) {
      showAlert("You must be at least 18 years old.", "warning");
      return;
    }

    const user = {
      firstName,
      middleName,
      lastName,
      email,
      password,
      username,
      birthday,
      gender: gender.toUpperCase(),
    };

    const formData = new FormData();
    const userBlob = new Blob([JSON.stringify(user)], {
      type: "application/json",
    });
    formData.append("user", userBlob);

    if (profilePicture) {
      const blob = new Blob([profilePicture], { type: "image/jpeg" }); // replace 'image/jpeg' with the actual MIME type of your file
      formData.append("profilePicture", blob, profilePicture.name);
    }

    AxiosInstance.post("/user/signup", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          showAlert("Signup successful! Please log in.", "success");
          navigate("/login", { state: { fromSignup: true } });
        }
      })
      .catch(function (response) {
        showAlert("Signup failed. Please try again.", "error");
      });
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}></Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Snackbar
            open={alert.open}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MuiAlert
              onClose={handleCloseAlert}
              severity={alert.severity}
              sx={{ width: "100%" }}
            >
              {alert.message}
            </MuiAlert>
          </Snackbar>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="verifyPassword"
                  label="Verify Password"
                  type="password"
                  id="verifyPassword"
                  autoComplete="new-password"
                  value={verifyPassword}
                  onChange={(e) => setVerifyPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="username"
                  label="Username"
                  type="text"
                  id="username"
                  autoComplete="username"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="gender"
                  options={["Male", "Female", "Other"]}
                  getOptionLabel={(option) => option}
                  filterOptions={(options, params) => {
                    const filtered = options.filter((option) =>
                      option
                        .toLowerCase()
                        .startsWith(params.inputValue.toLowerCase()),
                    );
                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Gender" />
                  )}
                  onInputChange={(event, newValue) => {
                    setGender(newValue);
                  }}
                  autoHighlight
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="day"
                  options={days}
                  getOptionLabel={(option) => option.toString()}
                  renderInput={(params) => (
                    <TextField {...params} label="Day" />
                  )}
                  onInputChange={(event, newValue) => {
                    setDay(newValue);
                  }}
                  autoHighlight
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="month"
                  options={months}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} label="Month" />
                  )}
                  onInputChange={(event, newValue) => {
                    setMonth(newValue);
                  }}
                  autoHighlight
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="year"
                  options={years}
                  getOptionLabel={(option) => option.toString()}
                  renderInput={(params) => (
                    <TextField {...params} label="Year" />
                  )}
                  onInputChange={(event, newValue) => {
                    setYear(newValue);
                  }}
                  autoHighlight
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  id="profilePicture"
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={(e) => setProfilePicture(e.target.files[0])}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUploadClick}
                  sx={{ mb: 2 }} // Increase top margin
                >
                  Upload Profile Picture
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
