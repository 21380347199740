import React, { useCallback, useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import "./PlantInfoPage.css";
import { FaBug, FaCut, FaSeedling, FaTint } from "react-icons/fa";
import JournalTab from "./JournalTab";
import PlantInfoImageCard from "./PlantInfoImageCard";
import GrowTagTabNav from "./GrowTagTabNav";
import AxiosInstance from "../../startup/startup";
import DetailsTab from "./DetailsTab";
import QRCodeSettingsTab from "./QRCodeSettingsTab";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const PlantInfoPage = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [plantInfo, setPlantInfo] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  useEffect(() => {
    // Flag to track if refresh token has been attempted
    let refreshAttempted = false;

    // Function to fetch user information
    const fetchUserInfo = () => {
      AxiosInstance.get("/user/me")
        .then((response) => {
          // If user is successfully fetched
          setIsLoggedIn(true);
          console.log("User data: ", response.data);

          // Fetch additional plant information
          AxiosInstance.get(`/user-grow-tag/${id}`, {})
            .then((response) => {
              setPlantInfo(response.data);
              if (response.data.visibility === "PRIVATE") {
                setTabValue(1);
              }
              console.log("Plant information: ", response.data);
            })
            .catch((error) => {
              navigate("/dashboard/planttag");
              // If there's an error fetching plant information
              setIsLoggedIn(false);
              setError(error.message);
            });
        })
        .catch((error) => {
          // If there's an error fetching user

          // Log the error
          console.log("Error fetching user: ", error);

          // If refresh token hasn't been attempted yet
          if (!refreshAttempted) {
            // Set flag to indicate refresh attempt
            refreshAttempted = true;

            // Try refreshing the authentication token
            AxiosInstance.post("/auth/refresh")
              .then(() => {})
              .catch((error) => {
                // If there's an error refreshing the token
                console.error("Error refreshing token: ", error);

                // Fetch the plant name and redirect to Wikipedia
                AxiosInstance.get(`/user-grow-tag/getPlantName/${id}`)
                  .then((response) => {
                    // If plant name is fetched successfully, redirect to Wikipedia page
                    let wikipediaLink = response.data;
                    console.log("Plant name: ", wikipediaLink);
                    window.location.href = wikipediaLink;
                  })
                  .catch((error) => {
                    // If there's an error fetching plant name
                    console.error("Error fetching plant name: ", error);
                  });
              });
          }
        });
    };

    // Call fetchUserInfo function when component mounts
    fetchUserInfo();
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount in class components

  const iconMapper = (type) => {
    switch (type) {
      case "Water":
        return <FaTint />;
      case "Fertilizer":
        return <FaSeedling />;
      case "Pruning":
        return <FaCut />;
      case "Pest":
        return <FaBug />;
      default:
        return null;
    }
  };

  return (
    <div>
      <IconButton onClick={() => navigate("/dashboard/growtags")}>
        <ArrowBackIcon />
      </IconButton>
      <Container maxWidth="sm">
        <Grid container spacing={3}>
          {error && (
            <Grid item xs={12}>
              <p>Error: {error}</p>
            </Grid>
          )}
          <Grid item xs={12} style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <PlantInfoImageCard
                name={plantInfo.name}
                imageUrl={plantInfo.imageUrl}
                width="100%"
              />
            </div>
          </Grid>
          {plantInfo.visibility === "PUBLIC" && (
            <Grid item xs={12} style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <GrowTagTabNav value={tabValue} onChange={handleTabChange} />
              </div>
            </Grid>
          )}
          {tabValue === 0 && plantInfo.visibility === "PUBLIC" && (
            <JournalTab />
          )}
          {tabValue === 1 && <DetailsTab plantInfo={plantInfo} />}
          {tabValue === 2 && plantInfo.visibility === "PUBLIC" && (
            <QRCodeSettingsTab plantInfo={plantInfo} nfcTagId={id} />
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default PlantInfoPage;
