import React from "react";
import { TextField } from "@mui/material";

function DescriptionStep({ plantData, setPlantData }) {
  const handleChange = (event) => {
    console.log(event.target.name, event.target.value);
    setPlantData({
      ...plantData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div>
      <TextField
        name="description"
        label="Add a description"
        multiline
        rows={4}
        value={plantData.description}
        onChange={handleChange} // Add this line
      />
    </div>
  );
}

export default DescriptionStep;
