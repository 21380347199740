import { combineReducers } from 'redux';

const userProfile = (userProfile = null, action) => {
	if (action.type === 'SET_USER_PROFILE') {
		return action.payload;
	}
	return userProfile;
};

export default combineReducers({
	userProfile: userProfile
});