import { useState } from "react";

export const usePlantSelection = (initialValue) => {
  const [isPlantSelected, setIsPlantSelected] = useState(initialValue);

  const handlePlantSelection = (newValue) => {
    setIsPlantSelected(newValue !== "");
  };

  return [isPlantSelected, handlePlantSelection];
};
