import AxiosInstance from "../startup/startup";

export const fetchPlantNames = async () => {
  try {
    const response = await AxiosInstance.get("/plant/getAllPlantNames");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch plant names:", error);
  }
};

export const fetchPlantTags = async () => {
  try {
    const response = await AxiosInstance.get("/user-grow-tag");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch grow tags:", error);
  }
};
