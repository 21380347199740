import SocialFeed from "./SocialFeed";
import Events from "./Events";
import Weather from "./Weather";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Main() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        height: "100vh",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <div style={{ flex: 1 }}>
          <Weather />
        </div>
        <div style={{ flex: 1 }}>
          <Events />
        </div>
        <div style={{ flex: 1 }}>
          <SocialFeed />
        </div>
      </div>
      {!isMobile && (
        <div style={{ flex: 1, overflow: "auto" }}>
          <SocialFeed />
        </div>
      )}
    </div>
  );
}
