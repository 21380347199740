import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  CircularProgress,
} from "@mui/material";
import PlantOriginStep from "../components/PlantOriginStep";
import PlantDetailsStep from "../components/PlantDetailsStep";
import DescriptionStep from "../components/DescriptionStep";
import VisibilityStep from "../components/VisibilityStep";
import AxiosInstance from "../startup/startup";

function AddPlantDialog({ open, handleClose, plantTags }) {
  const [activeStep, setActiveStep] = useState(0);
  const [plantData, setPlantData] = useState({
    plantId: "",
    name: "",
    newPlantName: "",
    plantType: "seed",
    visibility: "PUBLIC",
    location: "",
    plantedDate: new Date().toISOString().split("T")[0],
    description: "",
    isClone: false,
    isOrganic: false,
    displayWikipediaLink: false,
  });
  const [confirmClose, setConfirmClose] = useState(false);
  const [loading, setLoading] = useState(false); // New state for loading
  const [isFormValid, setIsFormValid] = useState(false);

  const steps = [
    "Plant's Origin",
    "Plant Details",
    "Description",
    "Visibility",
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const resetDialog = () => {
    console.log("resetting");
    setActiveStep(0);
    setPlantData({
      plantId: "",
      name: "",
      newPlantName: "",
      plantType: "seed",
      visibility: "PUBLIC",
      location: "",
      plantedDate: "",
      logTypeDescription: "",
      isClone: false,
      isOrganic: false,
      displayWikipediaLink: false,
    });
  };

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      setConfirmClose(true);
    } else {
      handleClose();
      resetDialog();
    }
  };

  const handleConfirmClose = (confirm) => {
    if (confirm) {
      handleClose();
      resetDialog();
    }
    setConfirmClose(false);
  };

  useEffect(() => {
    // Check if all fields have data
    if (
      plantData.name !== "" &&
      plantData.name !== null &&
      plantData.location !== "" &&
      plantData.location !== null
    ) {
      setIsFormValid(true);
    } else if (plantData.plantType === "seed" && !plantData.plantedDate) {
      setIsFormValid(false);
    } else {
      setIsFormValid(false);
    }
  }, [plantData]);

  const handleFinish = () => {
    setLoading(true); // Start loading
    AxiosInstance.post("/user-grow-tag", plantData)
      .then((response) => {
        console.log("New plant tag created:", response.data);
        plantTags.push(response.data);
      })
      .catch((error) => {
        console.error("Failed to create new plant tag:", error);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false); // Stop loading after 10 seconds
          handleClose();
          resetDialog();
        }, 2000);
      });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PlantOriginStep
            plantData={plantData}
            setPlantData={setPlantData}
            plantTags={plantTags}
          />
        );
      case 1:
        return (
          <PlantDetailsStep plantData={plantData} setPlantData={setPlantData} />
        );
      case 2:
        return (
          <DescriptionStep plantData={plantData} setPlantData={setPlantData} />
        );
      case 3:
        return (
          <VisibilityStep plantData={plantData} setPlantData={setPlantData} />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth={true}
        maxWidth={"md"}
        scroll="paper"
      >
        <DialogTitle>
          {steps[activeStep]} - Step {activeStep + 1} of {steps.length}
        </DialogTitle>
        <DialogContent>{getStepContent(activeStep)}</DialogContent>
        <DialogActions>
          {activeStep === 1 || activeStep === 2 || activeStep === 3 ? (
            <Button onClick={handleBack} color="primary">
              Back
            </Button>
          ) : (
            <Button
              onClick={handleDialogClose}
              color="primary"
              disabled={activeStep !== 0}
            >
              Cancel
            </Button>
          )}
          <Button
            disabled={
              loading ||
              (activeStep === 1 && !isFormValid) ||
              (activeStep === 0 &&
                plantData.plantType === "seed" &&
                !plantData.plantedDate)
            }
            onClick={
              activeStep === steps.length - 1 ? handleFinish : handleNext
            }
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : activeStep === steps.length - 1 ? (
              "Finish"
            ) : (
              "Next"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmClose} onClose={() => handleConfirmClose(false)}>
        <DialogTitle>Confirm Close</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to close the dialog? Any unsaved changes will
            be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmClose(false)}>Cancel</Button>
          <Button onClick={() => handleConfirmClose(true)}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddPlantDialog;
