import { Paper, Tab, Tabs } from "@material-ui/core";
import * as PropTypes from "prop-types";
import React from "react";

function GrowTagTabNav(props) {
  return (
    <Paper>
      <Tabs value={props.value} onChange={props.onChange} variant="fullWidth">
        <Tab label="Journal" />
        <Tab label="Plant Info" />
        <Tab label="Tools" />
      </Tabs>
    </Paper>
  );
}

GrowTagTabNav.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default GrowTagTabNav;
