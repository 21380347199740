import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Switch } from "@mui/material";
import React from "react";

function GrowTagSetting() {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography variant="h6" style={{ padding: "8px" }}>
          Settings
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ justifyContent: "center" }}>
        {/* Center the QR Code */}
        <div style={{ textAlign: "center" }}>
          {/* Set the QR Code value to "http://localhost:3000" */}
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Public Mode: Basic plant information is visible to everyone."
              />
              <FormControlLabel
                control={<Switch />}
                label="Private Mode: Only the user can view the detailed information. No data is shown to strangers."
              />
              <FormControlLabel
                control={<Switch />}
                label="Friend Mode: Allows friends or selected users to see detailed plant data, perhaps useful for plant enthusiasts in a community or group."
              />
              <FormControlLabel
                control={<Switch />}
                label="Watering Reminder"
              />
              <FormControlLabel
                control={<Switch />}
                label="Fertilizer Reminder"
              />
            </FormGroup>
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default GrowTagSetting;
