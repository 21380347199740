import { useEffect, useState } from "react";
import AxiosInstance from "../../../startup/startup";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  InputLabel,
  MenuItem,
  useTheme,
  useMediaQuery,
  CardContent,
  Grid,
  Select,
  Typography,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@material-ui/core";
import AddPlantDialog from "../../../dialogs/AddPlantDialog";

function PlantTagList(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const nav = useNavigate();
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [plantedFromSeed, setPlantedFromSeed] = useState(false);
  const [seedPlantDate, setSeedPlantDate] = useState("");

  const [open, setOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [newPlantTag, setNewPlantTag] = useState({
    name: "",
    location: "",
    description: "",
    plantId: 0,
    visibility: "PUBLIC",
  });

  const [plantTags, setPlantTags] = useState([]);
  const [plantNames, setPlantNames] = useState([]);
  const [selectedPlants, setSelectedPlants] = useState([]); // [{name: "Rose", id: 1}, {name: "Tulip", id: 2}

  const fetchPlantNames = async () => {
    try {
      const response = await AxiosInstance.get("/plant/getAllPlantNames");
      setPlantNames(response.data);
      console.log("Plant names:", response.data);
    } catch (error) {
      console.error("Failed to fetch plant names:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setNewPlantTag({ ...newPlantTag, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    // Validate the input and make a POST request to the server to create the new plant tag.
    // After the server responds, close the dialog and refresh the table.
    AxiosInstance.post("/user-grow-tag", newPlantTag)
      .then((response) => {
        console.log("New plant tag created:", response.data);
        setPlantTags((prevPlantTags) => [...prevPlantTags, response.data]);
        handleClose();
        // Refresh the table
        // You may want to fetch the plant tags again here to ensure the table is up-to-date
      })
      .catch((error) => {
        console.error("Failed to create new plant tag:", error);
      });
  };

  useEffect(() => {
    console.log("props.userCredentials", props);
    if (props.userCredentials !== null) {
      AxiosInstance.get("/user-grow-tag").then(function (response) {
        console.log("responsey", response.data);
        setPlantTags(response.data);
      });
      // Fetch previously inputted locations when the component mounts
      // AxiosInstance.get("/api/locations") // replace with your API endpoint
      //   .then((response) => setLocations(response.data))
      //   .catch((error) => console.error("Failed to fetch locations:", error));
      setLocations(["Indoor", "Outdoor", "Greenhouse", "Hydroponic"]);
    } else {
      AxiosInstance.get("/auth/login").then(function (response) {
        props.userProfile(response.data);
        nav("/dashboard");
      });
    }
    fetchPlantNames();
  }, []);

  return (
    <div>
      <h1>Grow Tag List</h1>
      <AddPlantDialog
        open={open}
        handleClose={handleClose}
        plantTags={plantTags}
      />
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add New Grow Tag
      </Button>
      {selectedPlants.length > 0 && (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setIsDeleteDialogOpen(true)}
        >
          Delete Selected
        </Button>
      )}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the following plants?
            {selectedPlants.map((plantId) => {
              const plantTag = plantTags.find(
                (plantTag) => plantTag.growTagId === plantId,
              );
              return <li key={plantId}>{plantTag.name}</li>;
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              // Delete the selected plants here
              selectedPlants.forEach((plantId) => {
                AxiosInstance.delete(`/user-grow-tag/${plantId}`)
                  .then((response) => {
                    console.log(
                      `Grow tag with id ${plantId} deleted:`,
                      response.data,
                    );
                    // Remove the deleted plant tag from the plantTags state
                    setPlantTags((prevPlantTags) =>
                      prevPlantTags.filter(
                        (plantTag) => plantTag.growTagId !== plantId,
                      ),
                    );
                  })
                  .catch((error) => {
                    console.error(
                      `Failed to delete grow tag with id ${plantId}:`,
                      error,
                    );
                  });
              });
              // Clear the selectedPlants state
              setSelectedPlants([]);
              // Close the dialog
              setIsDeleteDialogOpen(false);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {isMobile ? (
        <Grid container spacing={2}>
          {plantTags.map((plantTag, index) => (
            <Grid item xs={12} key={index}>
              <Card>
                <CardContent>
                  <Checkbox
                    checked={selectedPlants.includes(plantTag.growTagId)}
                    onChange={() => {
                      if (selectedPlants.includes(plantTag.growTagId)) {
                        setSelectedPlants(
                          selectedPlants.filter(
                            (id) => id !== plantTag.growTagId,
                          ),
                        );
                      } else {
                        setSelectedPlants([
                          ...selectedPlants,
                          plantTag.growTagId,
                        ]);
                      }
                    }}
                  />
                  <Typography variant="h6">{plantTag.name}</Typography>
                  <Typography variant="body2">
                    Description: {plantTag.description}
                  </Typography>
                  <Typography variant="body2">
                    Location: {plantTag.location}
                  </Typography>
                  <Typography variant="body2">
                    Planted Date: {formatDate(plantTag.plantedDate)}
                  </Typography>
                  <Link to={`/growtag/${plantTag.nfcTagId}`}>View Tag</Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <input
                    type="checkbox"
                    checked={isAllChecked}
                    onChange={() => {
                      setIsAllChecked(!isAllChecked);
                      if (!isAllChecked) {
                        setSelectedPlants(
                          plantTags.map((plantTag) => plantTag.growTagId),
                        );
                      } else {
                        setSelectedPlants([]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Plant Name</TableCell>
                <TableCell>Grow Tag Created Date</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Grow Tag</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plantTags && plantTags.length > 0 ? (
                plantTags.map((plantTag, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <input
                        type="checkbox"
                        checked={selectedPlants.includes(plantTag.growTagId)}
                        onChange={() => {
                          if (selectedPlants.includes(plantTag.growTagId)) {
                            setSelectedPlants(
                              selectedPlants.filter(
                                (id) => id !== plantTag.growTagId,
                              ),
                            );
                          } else {
                            setSelectedPlants([
                              ...selectedPlants,
                              plantTag.growTagId,
                            ]);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>{plantTag.name}</TableCell>
                    <TableCell>{plantTag.description}</TableCell>
                    <TableCell>
                      {plantTag.plantDto ? plantTag.plantDto.name : ""}
                    </TableCell>
                    <TableCell>{formatDate(plantTag.plantedDate)}</TableCell>
                    <TableCell>{plantTag.location}</TableCell>
                    <TableCell>
                      <Link to={`/growtag/${plantTag.nfcTagId}`}>View Tag</Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10}>
                    There are no grow tags available. Please create a new one.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default PlantTagList;
