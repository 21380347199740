import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import { useNavigate, useLocation } from "react-router-dom";

export const MainListItems = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    { name: "Dashboard", path: "/dashboard", icon: <DashboardIcon /> },
    {
      name: "Grow Tags",
      path: "/dashboard/growtags",
      icon: <ShoppingCartIcon />,
    },
    {
      name: "My Seed Vault",
      path: "/dashboard/myseedvault",
      icon: <PeopleIcon />,
    },
    {
      name: "Seed Store",
      path: "/dashboard/seedstore",
      icon: <BarChartIcon />,
    },
    { name: "Settings", path: "/dashboard/settings", icon: <LayersIcon /> },
    // Add more menu items here as needed
  ];

  return (
    <React.Fragment>
      {menuItems.map((item) => (
        <ListItemButton
          key={item.name}
          onClick={() => navigate(item.path)}
          selected={location.pathname === item.path} // Highlight if current path
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      ))}
    </React.Fragment>
  );
};
