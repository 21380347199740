import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import QRCode from "qrcode.react";
import * as PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import { ListItem } from "@mui/material";

function GrowTagQRCode(props) {
  const [copySuccess, setCopySuccess] = useState("");
  const qrCodeRef = useRef(null);

  const copyToClipboard = () => {
    const url = "http://192.168.50.167:3000/growtag/" + props.nfcTagId;
    const tempElem = document.createElement("textarea");
    tempElem.value = url;
    document.body.appendChild(tempElem);
    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);
    setCopySuccess("Copied!");
    setTimeout(() => {
      setCopySuccess("");
    }, 5000); // Reset copySuccess after 5 seconds
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-content"
        id="panel-header"
      >
        <Typography variant="h6" style={{ padding: "8px" }}>
          QR Code
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ justifyContent: "center" }}>
        {/* Center the QR Code */}
        <div style={{ textAlign: "center" }}>
          <Grid container spacing={2}>
            {/* ... other components ... */}
            <Grid item xs={12}>
              <Typography variant="body1">
                Click on QR code to copy URL
              </Typography>
              <QRCode
                ref={qrCodeRef}
                value={"http://192.168.50.167:3000/growtag/" + props.nfcTagId}
                size={200}
                onClick={copyToClipboard}
              />
              {copySuccess && (
                <div style={{ color: "green" }}>{copySuccess}</div>
              )}
              <Box
                mt={2}
                p={1}
                bgcolor="background.paper"
                borderRadius="borderRadius"
              >
                <Typography variant="body2">
                  <strong>Instructions for iOS users:</strong>
                </Typography>
                <List>
                  <ListItem>
                    1. Download the "NFC Tools" app from the App Store.
                  </ListItem>
                  <ListItem>
                    2. Open the app and click on the "Write" button.
                  </ListItem>
                  <ListItem>
                    3. Click on the "Add a record" button and select "URL/URI".
                  </ListItem>
                  <ListItem>
                    4. Click on the text input field, paste the copied URL and
                    click "OK".
                  </ListItem>
                  <ListItem>
                    5. Click on the "Write" button and bring your phone to the
                    NFC chip to write to it.
                  </ListItem>
                </List>
              </Box>
              <Box
                mt={2}
                p={1}
                bgcolor="background.paper"
                borderRadius="borderRadius"
              >
                <Typography variant="body2">
                  <strong>Instructions for Android users:</strong>
                </Typography>
                <List>
                  <ListItem>
                    1. Download the "NFC Tools" app from the Google Play Store.
                  </ListItem>
                  <ListItem>
                    2. Open the app and click on the "Write" button.
                  </ListItem>
                  <ListItem>
                    3. Click on the "Add a record" button and select "URL/URI".
                  </ListItem>
                  <ListItem>
                    4. Click on the text input field, paste the copied URL and
                    click "OK".
                  </ListItem>
                  <ListItem>
                    5. Click on the "Write" button and bring your phone to the
                    NFC chip to write to it.
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

GrowTagQRCode.propTypes = {
  plantInfo: PropTypes.shape({
    isGrowTent: PropTypes.bool,
    isShadeCloth: PropTypes.bool,
    sproutsIn: PropTypes.string,
    avoidGrowingWith: PropTypes.string,
    isOrganic: PropTypes.bool,
    description: PropTypes.string,
    isContainer: PropTypes.bool,
    isSquareFoot: PropTypes.bool,
    isIndoor: PropTypes.bool,
    harvestInstructions: PropTypes.string,
    isAeroponic: PropTypes.bool,
    isAquaponic: PropTypes.bool,
    isHugelkultur: PropTypes.bool,
    fruitSize: PropTypes.string,
    growingMethod: PropTypes.string,
    isOutdoor: PropTypes.bool,
    seedManufacturer: PropTypes.string,
    commonDiseases: PropTypes.string,
    howToGrow: PropTypes.string,
    growthStage: PropTypes.string,
    companionPlants: PropTypes.string,
    faq: PropTypes.any,
    imageUrl: PropTypes.string,
    plantSpacing: PropTypes.string,
    isClone: PropTypes.bool,
    matureHeight: PropTypes.string,
    commonPests: PropTypes.string,
    ec: PropTypes.string,
    isHydroponic: PropTypes.bool,
    dateOfPlanting: PropTypes.string,
    idealTemperature: PropTypes.string,
    ppm: PropTypes.string,
    isRaisedBed: PropTypes.bool,
    isBackToEden: PropTypes.bool,
    plantType: PropTypes.string,
    seedDepth: PropTypes.string,
    nfcTag: PropTypes.string,
    daysToMaturity: PropTypes.string,
    isColdFrame: PropTypes.bool,
    isVertical: PropTypes.bool,
    species: PropTypes.string,
    name: PropTypes.string,
    ph: PropTypes.string,
    isGreenhouse: PropTypes.bool,
    growMedium: PropTypes.string,
    minFullSunHours: PropTypes.string,
  }),
};

export default GrowTagQRCode;
