import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

function VisibilityStep({ plantData, setPlantData }) {
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const handleVisibilityChange = (event) => {
    setPlantData({
      ...plantData,
      visibility: event.target.value,
    });
  };

  const handleDisplayWikipediaLinkChange = (event) => {
    setPlantData({
      ...plantData,
      displayWikipediaLink: event.target.checked,
    });
  };

  return (
    <div>
      <FormControl component="fieldset">
        <RadioGroup
          row // This makes the radio buttons align horizontally
          aria-label="visibility"
          name="visibility"
          value={plantData.visibility}
          onChange={handleVisibilityChange}
        >
          <FormControlLabel
            value="PRIVATE"
            control={<Radio />}
            label="Private"
          />
          <FormControlLabel value="PUBLIC" control={<Radio />} label="Public" />
        </RadioGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={plantData.displayWikipediaLink}
              onChange={handleDisplayWikipediaLinkChange}
            />
          }
          label={
            <>
              Display the Wikipedia page to the public when they scan the grow
              tag.
              <IconButton onClick={() => setInfoDialogOpen(true)} size="small">
                <InfoIcon fontSize="inherit" />
              </IconButton>
            </>
          }
        />
      </FormControl>
      <Dialog open={infoDialogOpen} onClose={() => setInfoDialogOpen(false)}>
        <DialogTitle>Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            When a user who isn't you scans the grow tag, it will take them to
            the Wikipedia page of the plant.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInfoDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default VisibilityStep;
